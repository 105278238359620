import React from "react";
import {SEO_TYPE} from "../../constant";
import SeoElement from "../SeoElement"

const data = [
    {
        type: SEO_TYPE.H1,
        heading: "Welcome to Datum Consulting NZ "
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "We craft strategy and software to meet your unique needs."
    },
    {
        type: SEO_TYPE.H2,
        heading: "Our services"
    },
    // cloud
    {
        type: SEO_TYPE.H3,
        heading: "Cloud Services"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "Do you find yourself delaying your IT initiatives?"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "We help organisations get " +
            "moving by using packaged software, " +
            "SaaS, cloud services and bespoke solutions " +
            "to reduce delivery time frames and costs."
    },
    {
        type: SEO_TYPE.INTERNAL_LINK,
        to: "/cloud/",
        title: "CAPTURE THE BENEFITS OF THE CLOUD",
        label: "CAPTURE THE BENEFITS OF THE CLOUD"
    },
    {
        type: SEO_TYPE.UNORDERED_LIST,
        list: [
            "Cloud Transformation", "Architecture and Design", "API", "Integration",
            "Infrastructure automation", "Automation Testing", "Security Audit",
            "Compliance Audit", "Threat Monitoring", "Cost optimization", "DevOps",
            "CI/CD implementation", "Monitoring", "Logging", "AWS", "Azure"
        ]
    },
    // digital transformation
    {
        type: SEO_TYPE.H3,
        heading: "Digital Transformation"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "Do you feel missing out on the benefits of latest technology trend?"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "We help our clients develop digital strategies " +
            "and identify how digital " +
            "innovation can help them achieve " +
            "their business objectives."
    },
    {
        type: SEO_TYPE.INTERNAL_LINK,
        to: "/digital-transformation/",
        title: "ASSESS YOUR POTENTIAL",
        label: "ASSESS YOUR POTENTIAL"
    },
    {
        type: SEO_TYPE.UNORDERED_LIST,
        list: [
            "Assessment", "Digital Strategy", "Digital Transformation",
            "Agile Transformation", "Big Data & Analytics", "Cloud Computing",
            "Internet of Things (IoT)", "Cyber-security", "Augmented Reality",
            "Artificial Intelligence (AI)"
        ]
    },
    // software
    {
        type: SEO_TYPE.H3,
        heading: "Software Delivery"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "Do you need to enhance your current customer or employee experience?"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "We design and deliver leading edge " +
            "digital platforms and " +
            "applications across web, " +
            "mobile web and native mobile."
    },
    {
        type: SEO_TYPE.INTERNAL_LINK,
        to: "/software-development/",
        title: "ASSESS YOUR POTENTIAL",
        label: "ASSESS YOUR POTENTIAL"
    },
    {
        type: SEO_TYPE.UNORDERED_LIST,
        list: [
            "UX/UI design", "Web", "Mobile Application",
            "eCommerce", "Big Data", "Artificial Intelligence", "Cloud Computing",
            "DevOps", "Automation testing", "Maintenance"
        ]
    },
    // support
    {
        type: SEO_TYPE.H3,
        heading: "Enterprise Support"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "Do you lack end-to-end support for you business?"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "We can support your digital " +
            "applications and platforms 24/7 " +
            "to address your customers " +
            "availability requirements."
    },
    {
        type: SEO_TYPE.INTERNAL_LINK,
        to: "/enterprise-support/",
        title: "ENABLE YOUR TEAM DO THEIR BEST",
        label: "ENABLE YOUR TEAM DO THEIR BEST"
    },
    {
        type: SEO_TYPE.UNORDERED_LIST,
        list: [
            "Phone support", "Remote support", "Engineer visit",
            "Risk assessment", "On-demand support", "Cloud support", "Hosting support",
            "End-user support", "Software licencing", "Capacity planning",
            "Database backup and recovery", "Incident response", "Collaboration suits",
            "Office 365", "Google suits"
        ]
    },
    {
        type: SEO_TYPE.H2,
        heading: "Why work with us?"
    },
    {
        type: SEO_TYPE.H3,
        heading: "FOCUS ON OUTCOME"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "We start with your desired outcome " +
            "and work backward. Your business situation is " +
            "unique, and we bring deep industry knowledge " +
            "to work on your critical pain points."
    },
    {
        type: SEO_TYPE.H3,
        heading: "OPEN COMMUNICATION"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "A large part of any work is about communication. " +
            "We cultivate a collaborative culture " +
            "that foster open and frequent communication."
    },
    {
        type: SEO_TYPE.H3,
        heading: "HONEST AND TRUSTWORTHY"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "We believe it is possible to deliver IT project " +
            "right the first time. You can trust us as " +
            "a partner who keeps your best interests at heart."
    },
    {
        type: SEO_TYPE.INTERNAL_LINK,
        to: "/about/",
        title: "MORE ABOUT US",
        label: "MORE ABOUT US"
    },
    {
        type: SEO_TYPE.H2,
        heading: "Our story"
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "Datum Consulting - an engineering and " +
            "digital transformation " +
            "consultancy based in Auckland, New Zealand."
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "Since 2017, we have worked in companies ranging from the " +
            "size of startup to one of the the " +
            "4 big banks of Australia, to " +
            "help our clients build quality " +
            "software and undergo digital transformation."
    },
    {
        type: SEO_TYPE.PARAGRAPH,
        text: "Launching a product? " +
            "Not sure what your digital strategy is? " +
            "Need help with an existing projects?"
    },
    {
        type: SEO_TYPE.INTERNAL_LINK,
        to: "/contact/",
        title: "WORK WITH US",
        label: "WORK WITH US"
    },
    {
        type: SEO_TYPE.EXTERNAL_LINK,
        to: "https://linkedin.com/company/datumconsultingnz",
        title: "Linkedin",
        label: "Linkedin"
    },
    {
        type: SEO_TYPE.EXTERNAL_LINK,
        to: "https://twitter.com/datumhqNZ",
        title: "Twitter",
        label: "Twitter"
    },
    {
        type: SEO_TYPE.EXTERNAL_LINK,
        to: "https://www.facebook.com/DatumConsultingNZ",
        title: "Facebook",
        label: "Facebook"
    },
    {
        type: SEO_TYPE.EXTERNAL_LINK,
        to: "https://medium.com/datum-consulting-nz",
        title: "Medium",
        label: "Medium"
    }
];

const FakeContent = () => (
    <div
        style={{
            backgroundColor: "white",
            color: "white",
            height: "100vh",
            width: "100vw",
            position: "relative",
            top: "0",
            left: "0",
        }}
    >
        {data.map((element, index) => <SeoElement key={index} {...element}/>)}
    </div>
);

export default FakeContent;