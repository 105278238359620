import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Loadable from "react-loadable";
import Loading from "../components/Loading";
// import LoadingH1 from "../components/LoadingH1";
// import Header from "../components/index/Header";
// import Services from "../components/index/Services";
// import WhyWorkWithUs from "../components/index/WhyWorkWithUs";
// import OurStory from "../components/index/OurStory";
import FakeContent from "../components/index/FakeContent";

const Nav = Loadable({
  loader: () => import("../components/SharedComponents/Nav"),
  loading() {
    return <FakeContent />;
  },
});
const Header = Loadable({
  loader: () => import("../components/index/Header"),
  loading: Loading,
});
const ContactForm = Loadable({
  loader: () => import("../components/SharedComponents/ContactFormH1"),
  loading: Loading,
});
const Services = Loadable({
  loader: () => import("../components/index/Services"),
  loading: Loading,
});
const WhyWorkWithUs = Loadable({
  loader: () => import("../components/index/WhyWorkWithUs"),
  loading: Loading,
});
const OurStory = Loadable({
  loader: () => import("../components/index/OurStory"),
  loading: Loading,
});
const SubscribeFooter = Loadable({
  loader: () => import("../components/SharedComponents/SubscribeFooter"),
  loading: Loading,
});
const Footer = Loadable({
  loader: () => import("../components/SharedComponents/Footer"),
  loading: Loading,
});

export default () => {
  return (
    <Layout>
      <SEO
        title="Datum Consulting NZ | Cloud, Strategy, Software, IT Support"
        description={
          "Welcome to Datum Consulting - an " +
          "international professional service provider for " +
          "technology & systems, cloud services, " +
          "digital transformation, " +
          "strategy, performance improvement " +
          "software development, " +
          "enterprise support."
        }
        keywords={[
          `cloud services`,
          `digital strategy`,
          `software development`,
          `digital transformation`,
          `software development`,
          `enterprise support`,
          `it consulting`,
          `devops`,
          `agile transformation`,
          `big data`,
          `advanced analytics`,
          `aws`,
          `azure`,
        ]}
      />
      <Nav />
      <Header />
      <Services />
      <WhyWorkWithUs />
      <OurStory />
      <ContactForm />
      <SubscribeFooter />
      <Footer />
    </Layout>
  );
};
